import "typeface-roboto";
import "./index.css";

import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import React from "react";
import ReactDOM from "react-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.register();
