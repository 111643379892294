import { Add, ScreenShare, Today } from "@material-ui/icons";
import {
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";

import EventDialog from "../EventDialog/EventDialog";
import Footer from "../Footer";
import FullCalendar from "@fullcalendar/react";
import HelpDialog from "../HelpDialog";
import React from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { firestore } from "../../firebase";
import heLocale from "@fullcalendar/core/locales/he";
import interactionPlugin from "@fullcalendar/interaction";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import timeGridPlugin from "@fullcalendar/timegrid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    minHeight: "84vh",
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    background: "white",
  },
  largeAvatarDay: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    background: "white",
  },

  eventTitle: {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    fontSize: "1rem",
  },
  eventDescription: {
    overflowWrap: "break-word",
    whiteSpace: "normal",
  },
  eventContainer: {
    objectFit: "auto",
    display: "flex",
    maxWidth: "100%",
    boxShadow: " 0 2px 5px 0 hsla(0,0%,80.4%,.5)",
    boarderRadius: 50,
    minHight: 200,
    padding: 5,
  },
  avatarImage: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarImageDay: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  screenShare: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  showToday: {
    width: 700,
    maxWidth: "80%",
  },
}));

export default function Scheduler(props) {
  const classes = useStyles();
  const { centerId, classId } = useParams();
  const [eventDialogOpen, setEventDialogOpen] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [loadingEvents, setLoadingEvents] = React.useState(true);
  const [generalComment, setGeneralComment] = React.useState(false);
  const [birthday, setBirthday] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState();
  const [calView, setCalView] = React.useState("dayGridWeek");
  const [defaultClassLength, setDefaultClassLength] = React.useState("30");
  const [defaultEndOfYear, setDefaultEndOfYear] = React.useState(
    moment().endOf("year").format("DD/MM/YYYY")
  );

  const calendarRef = React.useRef();

  React.useEffect(() => {
    firestore
      .collection("events")
      .where("classId", "==", classId)
      .onSnapshot(
        (querySnapshot) => {
          try {
            var evs = [];
            querySnapshot.forEach((event) => {
              if (event.data().repetition === "years") {
                var date = event.data().startRecur;
                for (var i = 0; i < 15; i++) {
                  evs.push({
                    ...event.data(),
                    daysOfWeek: null,
                    startRecur: moment(date, "YYYY-MM-DD")
                      .add(i, "years")
                      .format(),
                    endRecur: moment(date, "YYYY-MM-DD")
                      .add(i, "years")
                      .add(1, "day")
                      .format(),
                    cc:
                      event.data().color === "-" ? "grey" : event.data().color,
                  });
                }
              } else {
                evs.push({
                  ...event.data(),
                  cc: event.data().color === "-" ? "grey" : event.data().color,
                });
              }
            });

            setEvents(evs);
            setLoadingEvents(false);
          } catch (error) {
            console.error("Error processing events:", error);
            setLoadingEvents(false);
          }
        },
        (error) => {
          console.error("Error in Firestore listener:", error);
          setLoadingEvents(false);
        }
      );
  }, [classId]);

  // This will update icons in case of database change
  // React.useEffect(() => {
  //   const ic = [];
  //   storage
  //     .ref()
  //     .child("iconsCohen")
  //     .listAll()
  //     .then((res) => {
  //       res.items.forEach((item) => {
  //         item
  //           .getDownloadURL()
  //           .then((url) => {
  //             ic.push({
  //               name: item.name.split(".")[0],
  //               image: url,
  //               firstLetter: item.name.split(".")[0][0],
  //             });
  //           })
  //           .then(() => {
  //             if (res.items.length === ic.length) {
  //               ic.sort(
  //                 (a, b) =>
  //                   parseFloat(a.name.split(")")[0]) -
  //                   parseFloat(b.name.split(")")[0])
  //               );
  //               console.log(ic);
  //               events.forEach((event) => {
  //                 if (event && event.icon) {
  //                   if (event.icon.split(")")[1])
  //                     var currentIcon = event.icon
  //                       .split(")")[1]
  //                       .split(".png")[0];
  //                   var newIcon = ic.find((icon) =>
  //                     icon.image.includes(currentIcon)
  //                   );
  //                   if (currentIcon && newIcon) {
  //                     firestore
  //                       .collection("events")
  //                       .doc(event.id)
  //                       .update({ icon: newIcon.image });
  //                   }
  //                 }
  //               });
  //             }
  //           });
  //       });
  //     });
  // }, [events]);

  React.useEffect(() => {
    firestore
      .collection("centers")
      .doc(centerId)
      .get()
      .then((snapshot) => {
        setDefaultClassLength(snapshot.data().defaultClassLength || "30");
        setDefaultEndOfYear(
          snapshot.data().defaultEndOfYear || defaultEndOfYear
        );
      });
    // eslint-disable-next-line
  }, [centerId]);

  React.useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(calView);
      calendarRef.current.getApi().setOption("height", "auto");
    }
  }, [calView]);

  const dropEvent = (change) => {
    const newDate = moment(change.oldEvent._instance.range.start).add(
      change.delta.days,
      "days"
    );
    const newEnd = moment(change.oldEvent._instance.range.end).add(
      change.delta.days,
      "days"
    );

    var update = {};
    if (change.oldEvent._def.recurringDef.typeData.daysOfWeek) {
      update = {
        end: newEnd.format(),
        date: newDate.format("YYYY-MM-DD"),
        startRecur: newDate.format(),
        start: newDate.format(),
        daysOfWeek: [newDate.day()],
      };
    } else {
      update = {
        end: newEnd.format(),
        endRecur: newEnd.format(),
        date: newDate.format("YYYY-MM-DD"),
        startRecur: newDate.format(),
        start: newDate.format(),
        daysOfWeek: null,
      };
    }
    firestore
      .collection("events")
      .doc(change.oldEvent._def.publicId)
      .update(update)
      .then(() => {});
  };

  const EventDetail = ({ event }) => {
    const content = (
      <Container className={classes.eventContainer}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant={"h6"} className={classes.eventTitle}>
              {event._def.extendedProps.repetition === "days" ? (
                <b>{event.title}</b>
              ) : (
                event.title
              )}
            </Typography>
            <Typography
              variant={"caption"}
              className={classes.eventDescription}
            >
              {event._def.extendedProps.description}
            </Typography>
            <br />
            {event._def.extendedProps.repetition !== "years" ? (
              <Typography variant="caption">
                {moment(event.start, moment.ISO_8601).format("HH:mm")} -{" "}
                {moment(event.end, moment.ISO_8601).format("HH:mm")}
              </Typography>
            ) : (
              <Typography variant="caption">כל היום</Typography>
            )}
            <br />
            {calView === "dayGridDay" && (
              <Typography variant="caption" style={{ whiteSpace: "pre" }}>
                {event._def.extendedProps.comment}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Avatar
              className={
                calView === "dayGridDay"
                  ? classes.largeAvatarDay
                  : classes.largeAvatar
              }
              style={{
                border: `5px solid ${event._def.extendedProps.cc}`,
              }}
            >
              <img
                alt={"avatar"}
                className={
                  calView === "dayGridDay"
                    ? classes.avatarImageDay
                    : classes.avatarImage
                }
                src={event._def.extendedProps.icon}
              />
            </Avatar>
          </Grid>
        </Grid>
      </Container>
    );
    return content;
  };

  const onEventClick = (info, _eve) => {
    firestore
      .collection("events")
      .doc(info.event._def.publicId)
      .get()
      .then((querySnapshot) => {
        setSelectedEvent(querySnapshot.data());
      });
    setEventDialogOpen(true);
  };

  return (
    <>
      <Paper className={classes.root}>
        <EventDialog
          open={eventDialogOpen}
          event={selectedEvent}
          defaultClassLength={defaultClassLength}
          defaultEndOfYear={defaultEndOfYear}
          generalComment={generalComment}
          birthday={birthday}
          onClose={() => {
            setEventDialogOpen(false);
            setSelectedEvent();
            setGeneralComment(false);
            setBirthday(false);
          }}
        />
        <Container className={classes.cardGrid} maxWidth="lg">
          <HelpDialog
            videoUrl={""}
            buttonStyle={{ position: "absolute", top: 65, left: 0 }}
          />

          {loadingEvents ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : (
            <div className={calView === "dayGridDay" ? classes.showToday : {}}>
              <ButtonGroup className={"hidden-print"}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  component={Link}
                  to={`/class/${centerId}/${classId}/present`}
                >
                  <ScreenShare className={classes.screenShare} />
                  הצגה
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={() => setEventDialogOpen(true)}
                >
                  <Add className={classes.screenShare} />
                  ארוע
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={() => {
                    setGeneralComment(true);
                    setEventDialogOpen(true);
                  }}
                >
                  <Add className={classes.screenShare} />
                  חשוב לזכור
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={() => {
                    setBirthday(true);
                    setEventDialogOpen(true);
                  }}
                >
                  <Add className={classes.screenShare} />
                  יום הולדת
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setCalView(
                      calView === "dayGridWeek" ? "dayGridDay" : "dayGridWeek"
                    );
                  }}
                >
                  <Today className={classes.screenShare} />
                  {calView === "dayGridWeek" ? "לוח יומי" : "לוח שבועי"}
                </Button>
              </ButtonGroup>
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridWeek"}
                locale={heLocale}
                hiddenDays={[5, 6, 7]}
                height="auto"
                editable={true}
                droppable={true}
                eventDrop={dropEvent}
                events={events}
                eventContent={EventDetail}
                eventClick={onEventClick}
                lazyFetching={false}
                customButtons={{
                  add: {
                    text: "+",
                    click: () => setEventDialogOpen(true),
                  },
                  print: {
                    text: "הדפסה",
                    click: () => window.print(),
                  },
                }}
                headerToolbar={{
                  right: "",
                  center: "",
                  left: "prev,next print today add",
                }}
              />
            </div>
          )}
        </Container>
      </Paper>
      <Footer />
    </>
  );
}

Scheduler.propTypes = {};
